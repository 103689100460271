<template>
  <section>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">จัดการผู้ใช้งานในระบบ</h3>
        <div class="card-tools">
          <div class="input-group input-group-sm" style="width: 400px;">
            <input type="text" name="table_search" v-model="search"  v-on:keyup.enter="searchUser()" class="form-control float-right" placeholder="Search">
            <div class="input-group-append">
              <button type="submit" class="btn btn-default" @click.prevent="searchUser()"><i class="fas fa-search"></i></button>
            </div>
            <button class="btn btn-sm btn-primary ml-2" @click.prevent="createModal()"  style=""><i class="fas fa-plus fa-fw"></i>เพิ่ม User </button>
            <button class="btn btn-sm btn-success ml-2" @click.prevent="getResults()"  style=""><i class="fas fa-sync-alt fa-fw"></i></button>
          </div>
        </div>
      </div>
<!--      <div class="card-body">-->
<!--        {{$v}}-->
<!--      </div>-->
      <div class="card-body">
        <div class="row">
          <div class="card-body table-responsive p-0" style="height: 650px;">
            <table class="table table-head-fixed table-hover table-bordered">
              <thead class="thead-light text-center">
              <tr>
                <th scope="col" style="background-color: rgb(197,196,196)">#</th>
                <th v-for="ths in th" :key="ths" style="background-color: rgb(197,196,196)">
                  {{ths}}
                </th>
                <th scope="col" style="background-color: rgb(197,196,196)">Config</th>
              </tr>
              </thead>
                <tbody>
                <tr v-for="(datas,index) in data.data" :key="datas.id">
                  <th scope="row" style="width: 15px;font-size: 12px">{{index+1}}</th>
                  <td style="font-size: 12px">{{datas.name }}</td>
                  <td style="font-size: 12px">{{datas.username }}</td>
                  <td style="font-size: 12px">{{datas.email }}</td>
                  <td style="width: 10%;font-size: 12px">{{datas.roleId === 1 ? 'Super Admin' : (datas.roleId === 2 ? 'Admin': "User") }}</td>
                  <td style="width: 8%;font-size: 12px">{{datas.company.comNameTh }}</td>
                  <td style="width: 10%;font-size: 12px">{{datas.createdAt | myDateThs}}</td>
                  <td style="width: 12%;font-size: 12px">
                    <ul style="list-style: none;margin-left: -35px" >
                      <li ><a href="" @click.prevent="resetPassword(datas,index)"><i class="fas fa-caret-right fa-fw"></i>Reset Password</a></li>
                      <li ><a href="" @click.prevent="editModal(datas,index)"><i class="fas fa-caret-right fa-fw"></i>แก้ไข</a></li>
                      <li ><a href="" @click.prevent="deleteModal(datas,index)"><i class="fas fa-caret-right fa-fw" ></i>ลบ</a></li>
                    </ul>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="float-lg-right">
          <b-pagination
              v-model="page"
              :total-rows="count"
              :per-page="pageSize"
              prev-text="Prev"
              next-text="Next"
              @change="handlePageChange"
          ></b-pagination>
        </div>

      </div>
    </div>
    <!-- /.card -->

<!--    <div>-->
<!--      <b-modal id="modal-1" title="BootstrapVue">-->
<!--        <p class="my-4">Hello from modal!</p>-->
<!--      </b-modal>-->
<!--    </div>-->

    <b-modal size="xl" id="modal-update" ref="modal"
             @ok="update"
             title="แก้ไขผู้ใช้งาน">
      <form ref="form" @submit.prevent="update">
        <div class="form-group col">
          <div class="col">
            <label>ชื่อผู้ใช้งาน</label>
          </div>
          <input
              v-model.trim="$v.form.name.$model"
              type="text"
              class="form-control"
              placeholder="Username"
              :class="{ 'is-invalid':$v.form.name.$error }"
          />
          <span v-if="!$v.form.name.required " class="invalid-feedback">required</span>
<!--          <span v-if="!$v.form.name.alphaNumAndDotValidator || !$v.form.name.alpha" class="invalid-feedback">incorrect format</span>-->
        </div>
        <div class="form-group col">
          <div class="col">
            <label>User สำหรับเข้าใช้งานระบบ</label>
          </div>
          <input
              v-model.trim="$v.form.username.$model"
              type="text"
              class="form-control"
              placeholder="Username"
              :class="{ 'is-invalid':$v.form.username.$error }"
          />
          <span v-if="!$v.form.username.required " class="invalid-feedback">required</span>
<!--          <span v-if="!$v.form.username.alphaNumAndDotValidator || !$v.form.username.alpha" class="invalid-feedback">incorrect format</span>-->
        </div>
        <div class="form-group col">
          <div class="col">
            <label>Email</label>
          </div>
          <input
              v-model.trim="$v.form.email.$model"
              type="email"
              class="form-control"
              placeholder="Username"
              :class="{ 'is-invalid':$v.form.email.$error }"
          />
          <span v-if="!$v.form.email.required || !$v.form.username.email " class="invalid-feedback">required/incorrect format</span>
        </div>
        <div class="form-group col">
          <div class="col">
            <label>Password</label>
          </div>
          <input
              disabled
              v-model.trim="$v.form.password.$model"
              type="password"
              class="form-control"
              placeholder="Username"
              :class="{ 'is-invalid':$v.form.password.$error }"
          />
          <span v-if="!$v.form.password.required " class="invalid-feedback">required</span>
          <span v-if="!$v.form.password.minLength" class="invalid-feedback">Passwords must be identical.</span>
        </div>
        <div class="form-group col">
          <div class="col">
            <label>Repeat Password</label>
          </div>
          <input
              disabled
              v-model.trim="$v.form.repeatPassword.$model"
              type="password"
              class="form-control"
              placeholder="Username"
              :class="{ 'is-invalid':$v.form.repeatPassword.$error }"
          />
          <span v-if="!$v.form.password.sameAsPassword " class="invalid-feedback">Passwords miss math</span>
        </div>
        <div class="form-group col">
          <div class="col">
            <label>ประเภทผู้ใช้งาน</label>
          </div>
            <select v-model.trim="$v.form.roleId.$model" class="form-control" :class="{ 'is-invalid': $v.form.roleId.$error }">
              <option disabled value="">เลือกสถานะ การใช้งาน</option>
              <option :value="1">Super Admin</option>
              <option :value="2">Admin</option>
              <option :value="3">User</option>
            </select>
            <span v-if="!$v.form.roleId.required " class="invalid-feedback">required</span>
        </div>
        <div class="form-group col ">
          <div class="col">
            <label>สาขา</label>
          </div>
            <select v-model.trim="$v.form.companyId.$model" class="form-control" :class="{ 'is-invalid': $v.form.companyId.$error }">
              <option disabled value="">Please select one</option>
              <option v-for="c in company" :key="c.companyId" :value="c.companyId">
                {{c.comNameTh}}
              </option>
            </select>
        </div>
      </form>
    </b-modal>
    <b-modal size="xl" id="modal-create" ref="modal"
             @ok="createUser"
             title="เพิ่มผู้ใช้งานใหม่">
      <form ref="form" @submit.prevent="createUser">
        <div class="form-group col">
          <div class="col">
            <label>ชื่อผู้ใช้งาน</label>
          </div>
          <input
              v-model.trim="$v.form.name.$model"
              type="text"
              class="form-control"
              placeholder="Username"
              :class="{ 'is-invalid':$v.form.name.$error }"
          />
          <span v-if="!$v.form.name.required " class="invalid-feedback">required</span>
          <span v-if="!$v.form.name.alphaNumAndDotValidator || !$v.form.name.alpha" class="invalid-feedback">incorrect format</span>
        </div>
        <div class="form-group col">
          <div class="col">
            <label>User สำหรับเข้าใช้งานระบบ</label>
          </div>
          <input
              v-model.trim="$v.form.username.$model"
              type="text"
              class="form-control"
              placeholder="Username"
              :class="{ 'is-invalid':$v.form.username.$error }"
          />
          <span v-if="!$v.form.username.required " class="invalid-feedback">required</span>
          <span v-if="!$v.form.username.alphaNumAndDotValidator || !$v.form.username.alpha" class="invalid-feedback">incorrect format</span>
        </div>
        <div class="form-group col">
          <div class="col">
            <label>Email</label>
          </div>
          <input
              v-model.trim="$v.form.email.$model"
              type="email"
              class="form-control"
              placeholder="Username"
              :class="{ 'is-invalid':$v.form.email.$error }"
          />
          <span v-if="!$v.form.email.required || !$v.form.username.email " class="invalid-feedback">required/incorrect format</span>
        </div>
        <div class="form-group col">
          <div class="col">
            <label>Password</label>
          </div>
          <input
              v-model.trim="$v.form.password.$model"
              type="password"
              class="form-control"
              placeholder="Username"
              :class="{ 'is-invalid':$v.form.password.$error }"
          />
          <span v-if="!$v.form.password.required " class="invalid-feedback">required</span>
          <span v-if="!$v.form.password.minLength" class="invalid-feedback">Passwords must be identical.</span>
        </div>
        <div class="form-group col">
          <div class="col">
            <label>Repeat Password</label>
          </div>
          <input
              v-model.trim="$v.form.repeatPassword.$model"
              type="password"
              class="form-control"
              placeholder="Username"
              :class="{ 'is-invalid':$v.form.repeatPassword.$error }"
          />
          <span v-if="!$v.form.password.sameAsPassword " class="invalid-feedback">Passwords miss math</span>
        </div>
        <div class="form-group col">
          <div class="col">
            <label>ประเภทผู้ใช้งาน</label>
          </div>
            <select v-model.trim="$v.form.roleId.$model" class="form-control" :class="{ 'is-invalid': $v.form.roleId.$error }">
              <option disabled value="">เลือกสถานะ การใช้งาน</option>
              <option :value="1">Super Admin</option>
              <option :value="2">Admin</option>
              <option :value="3">User</option>
            </select>
            <span v-if="!$v.form.roleId.required " class="invalid-feedback">required</span>
        </div>
        <div class="form-group col ">
          <div class="col">
            <label>สาขา</label>
          </div>
            <select v-model.trim="$v.form.companyId.$model" class="form-control" :class="{ 'is-invalid': $v.form.companyId.$error }">
              <option disabled value="">Please select one</option>
              <option v-for="c in company" :key="c.companyId" :value="c.companyId">
                {{c.comNameTh}}
              </option>
            </select>
        </div>
      </form>
    </b-modal>
    <b-modal size="md" id="modal-reset" ref="modal"
             @ok="resetPasswords"
             title="แก้ไขรหัสผ่าน">
      <form ref="form" @submit.prevent="resetPasswords">
        <div class="form-group col">
          <div class="col">
            <label>Password</label>
          </div>
          <input
              v-model.trim="$v.formReset.password.$model"
              type="password"
              class="form-control"
              placeholder="Username"
              :class="{ 'is-invalid':$v.formReset.password.$error }"
          />
          <span v-if="!$v.formReset.password.required " class="invalid-feedback">required</span>
          <span v-if="!$v.formReset.password.minLength" class="invalid-feedback">Passwords must be identical.</span>
        </div>
        <div class="form-group col">
          <div class="col">
            <label>Repeat Password</label>
          </div>
          <input
              v-model.trim="$v.formReset.repeatPassword.$model"
              type="password"
              class="form-control"
              placeholder="Username"
              :class="{ 'is-invalid':$v.formReset.repeatPassword.$error }"
          />
          <span v-if="!$v.formReset.repeatPassword.sameAsPassword " class="invalid-feedback">Passwords miss math</span>
        </div>
      </form>
    </b-modal>
  </section>
</template>

<script>
import axios from 'axios';
import { Form } from 'vform';
import { required, minLength,email,sameAs  } from "vuelidate/lib/validators";
import {mapActions} from "vuex";
export default {
  name: 'usermsg',
  data(){
    return{
      data:{},
      th:['ชื่อผู้ใช้งาน','User/login','email','ประเภทผู้ใช้งาน','สาขา','วันที่สร้าง'],
      page: 1,
      count: 0,
      pageSize: 0,
      Index: '',
      nameState: null,
      company: '',
      search: '',


      form : new Form ({
        userId:'',
        name : '',
        username : '',
        password : '',
        repeatPassword : '',
        email : '',
        companyId : '',
        roleId : '',
        createdAt : '',
        updatedAt : '',
      }),


      formReset : new Form ({
        userId:'',
        password : '',
        repeatPassword : '',
      })


    }
  },

  validations: {
    form : {
      name: {
        required,
        minLength: minLength(3)
      },
      username: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
      roleId: {
        required,
      },
      companyId: {
        required,
      },
      password: {
        required,
        minLength: minLength(8)
      },
      repeatPassword: {
        sameAsPassword: sameAs('password')
      }
    },

    formReset : {
      password: {
        required,
        minLength: minLength(8)
      },
      repeatPassword: {
        sameAsPassword: sameAs('password')
      }
    }

  },
  mounted() {
    this.getResults()
  },
  methods:{
    ...mapActions(['logout']),
    async getResults(){
       await axios.get('api/user/userIndex'+'?page=' + this.page )
          .then(response => {
            if (response.data.success === true)
            this.data = response.data
            this.count = response.data.totalItems;
            this.pageSize = response.data.limit;
          }).catch((error)=>{
             if (error.response.status === 401) {
              this.logout()
             }
           });
       await axios.get('api/company/getCompany')
           .then(response => {
             if (response.status === 200)
             this.company = response.data
           });
    },
    handlePageChange(value) {
      this.page = value;
      this.getResults();
    },

     editModal(data,index){
      // console.log(data)
      // this.form.reset()
      // this.form.clear()
      this.$v.$reset();
      this.Index = index;
      this.form.fill(data);
      this.form.repeatPassword = data.password;
      this.$bvModal.show('modal-update')

    },
    async update(bvModalEvt){
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$pending || this.$v.form.$error) return;
      await this.form.put('api/user/userUpdate/'+this.form.userId)
          .then( async response => {
            if (response.data.success === true ){
              await this.getResults()
              // await this.data.data.splice(this.Index,1,response.data.data)
              this.$bvModal.hide('modal-update')
            }
          }).catch((error) => {
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
            }
          });
      // this.$nextTick(() => {
      //   // this.$bvModal.hide('modal-1')
      //   // console.log(123465)
      // })

    },

    createModal(){
      this.$v.$reset();
      this.form.reset()
      this.$bvModal.show('modal-create')
    },

    async createUser(bvModalEvt){
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$pending || this.$v.form.$error) return;
      await this.form.post('api/user/register')
          .then( async response => {
            if (response.data.success === true ){
              await this.getResults()
              // await this.data.data.unshift(response.data.data)
              this.$bvModal.hide('modal-create')
            }
          }).catch((error) => {
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
            }
          });
    },

    toast(msg){
      this.$swal({
        position: 'top-end',
        icon: 'success',
        toast: true,
        title: msg,
        showConfirmButton: false,
        timer: 1500
      })
    },

    // handleOk(bvModalEvt) {
    //   // Prevent modal from closing
    //   bvModalEvt.preventDefault()
    //   // Trigger submit handler
    //   this.update()
    // },

    async searchUser(){
      if (this.search){
        await axios.post('api/user/userFind',{ search : this.search})
          .then( async response => {
              if (response.data.success === true ){
                this.data = response.data
                this.count = response.data.totalItems;
                this.pageSize = response.data.limit;
                this.toast('Your work has been saved')
              }
            }).catch((error) => {
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
              }
            });

      }else {
        this.$swal('something wrong', 'กรุณาใส่ข้อมูล', 'error');
      }
    },

    async deleteModal(data,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be delete this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then( async (result) => {
        if (result.value) {
          await axios.delete('api/user/deleteUser/'+data.userId)
              .then(async(res) => {
                if (!res.data.success === true){
                  this.$swal({
                    type: 'error',
                    title: 'ข้อมูลไม่ถูกต้อง',
                    text: 'ข้อมูลไม่ถูกต้อง',
                    showConfirmButton: true,
                    timer: 5000
                  })
                }else {
                  await this.data.data.splice(index,1)
                  this.$swal(
                      'Deleted!',
                      'Your file has been deleted.',
                      'success'
                      )
                  }
                })
        }
      }).catch((err) => {
        this.$swal("Failed!", "There was something wronge", "warning");
        console.log(err)
      })
    },
    async resetPassword(data,index){
      this.$v.$reset();
      this.formReset.reset()
      this.Index = index;
      this.formReset.userId = data.userId;
      this.$bvModal.show('modal-reset')
    },

    async resetPasswords(bvModalEvt){
      bvModalEvt.preventDefault()
      this.$v.formReset.$touch()
      if (this.$v.formReset.$pending || this.$v.formReset.$error) return;
      await this.formReset.post('api/user/resetPasswords')
          .then( async response => {
            if (response.data.success === true ){
              await this.getResults()
              // await this.data.data.splice(this.Index,1,response.data.data)
              this.$bvModal.hide('modal-reset')
            }
          }).catch((error) => {
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
            }
          });
    },

  }
}
</script>
